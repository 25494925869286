import {Action, createSelector, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {patch, removeItem} from '@ngxs/store/operators';
import {LoadWorkupList, RemoveWorkupFromList, SaveWorkupList} from './workup-list.actions';
import {WorkupListApiService} from '@matchsource/api/workup-list';
import {WorkupListModel} from '@matchsource/models/workup';

export interface WorkupStateListModel {
  workups: WorkupListModel[];
}

@Injectable()
@State<WorkupStateListModel>({
  name: 'workupList',
  defaults: {
    workups: null,
  },
})
@Injectable()
export class WorkupListState {
  constructor(private readonly api: WorkupListApiService) {}

  @Selector([WorkupListState])
  static workups(state: WorkupStateListModel): WorkupListModel[] {
    return state.workups;
  }

  static getWorkup(workupId: number) {
    return createSelector([WorkupListState], (state: WorkupStateListModel) =>
      state.workups.find(item => item.workupId === workupId)
    );
  }

  @Action(LoadWorkupList)
  loadWorkups(ctx: StateContext<WorkupStateListModel>, {patientId}: LoadWorkupList) {
    const workups = this.api.get(patientId);
    ctx.setState(
      patch({
        workups,
      })
    );
  }

  @Action(SaveWorkupList)
  save(ctx: StateContext<WorkupStateListModel>, {patientId, workup}: SaveWorkupList) {
    const workups = ctx.getState().workups;
    const items = workups.filter(item => item.workupId !== workup.workupId);
    items.push(workup);
    this.api.save(patientId, items);
    ctx.setState(
      patch({
        workups: items,
      })
    );
  }

  @Action(RemoveWorkupFromList)
  removeWorkup(ctx: StateContext<WorkupStateListModel>, {patientId, workupId}: RemoveWorkupFromList) {
    ctx.setState(
      patch({
        workups: removeItem<WorkupListModel>(workup => workup.workupId === workupId),
      })
    );

    const workupLength = ctx.getState().workups?.length;
    return workupLength ? this.api.save(patientId, ctx.getState().workups) : this.api.delete(patientId);
  }
}
