import {Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {LoadWorkupList, RemoveWorkupFromList, SaveWorkupList} from './workup-list.actions';
import {WorkupListState} from './workup-list.state';
import {WorkupListModel, FormValues} from '@matchsource/models/workup';
import {DonorWorkupModel} from '@matchsource/models/donor-workup';

export class WorkupListService {
  constructor(
    public readonly patientId: MsApp.Guid,
    private readonly store: Store
  ) {}

  load(): void {
    this.store.dispatch(new LoadWorkupList(this.patientId));
  }

  save(
    workupId: number,
    sourceId: MsApp.Guid,
    model: FormValues | DonorWorkupModel,
    type: string,
    formVersion?: string
  ): void {
    this.store.dispatch(new SaveWorkupList(this.patientId, {workupId, sourceId, model, type, formVersion}));
  }

  get(workupId: number): WorkupListModel {
    return this.store.selectSnapshot(WorkupListState.getWorkup(workupId));
  }

  has(workupId: number): boolean {
    return !!this.store.selectSnapshot(WorkupListState.getWorkup(workupId));
  }

  remove(workupId: number): void {
    this.store.dispatch(new RemoveWorkupFromList(this.patientId, workupId));
  }

  removeBySourceId(sourceId: MsApp.Guid): void {
    const workups = this.store.selectSnapshot(WorkupListState.workups);
    const {workupId} = workups.find(item => item.sourceId === sourceId) || {};
    if (workupId) {
      this.remove(workupId);
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class WorkupListFactory {
  constructor(private readonly store: Store) {}

  create(patientId: MsApp.Guid): WorkupListService {
    const workupListService = new WorkupListService(patientId, this.store);
    workupListService.load();

    return workupListService;
  }
}
