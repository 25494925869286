import {WorkupListModel} from '@matchsource/models/workup';

export class LoadWorkupList {
  static readonly type = '[Workup] Load workup list';

  constructor(public readonly patientId: MsApp.Guid) {}
}

export class SaveWorkupList {
  static readonly type = '[Workup] Save workup list';

  constructor(
    public readonly patientId: MsApp.Guid,
    public readonly workup: WorkupListModel
  ) {}
}

export class RemoveWorkupFromList {
  static readonly type = '[Workup] Remove workup from list';

  constructor(
    public readonly patientId: MsApp.Guid,
    public readonly workupId: number
  ) {}
}
