import {Injectable} from '@angular/core';
import {SessionStorageService} from 'ngx-webstorage';
import {isEmpty} from '@matchsource/utils';
import {WorkupListModel} from '@matchsource/models/workup';

const KEY_PREFIX = 'workup_';

const getStorageKey = (key: string) => `${KEY_PREFIX}${key.toUpperCase()}`;

@Injectable({
  providedIn: 'root',
})
export class WorkupListApiService {
  constructor(private readonly storage: SessionStorageService) {}

  save(patientId: string, workups: WorkupListModel[]): void {
    if (!isEmpty(workups)) {
      this.storage.store(getStorageKey(patientId), workups);
    } else {
      this.delete(patientId);
    }
  }

  get(patientId: string): WorkupListModel[] {
    return this.storage.retrieve(getStorageKey(patientId)) || [];
  }

  delete(patientId: string): void {
    this.storage.clear(getStorageKey(patientId));
  }
}
